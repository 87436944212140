import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Typography } from "@material-ui/core"

import PostPreview from "./postPreview"
import Tag from "../Tag/tag"

const BlogPosts = () => {
  const data = useStaticQuery(graphql`
    query BlogQuery {
      allCosmicjsBlogPosts {
        nodes {
          id
          metadata {
            post_date
            post_spoiler
            post_title
          }
          slug
        }
      }
    }
  `)
  const blogPosts = data.allCosmicjsBlogPosts.nodes
  //console.log(blogPosts)
  const renderBlogPostsList = () => {
    return blogPosts.map(post => <PostPreview key={post.id} post={post} />)
  }

  return (
    <Grid container justify="flex-start" alignItems="flex-start" direction="column">
      <Grid container justify="space-evenly" alignItems="center" spacing={2} direction="column" style={{ marginBottom: `2.0rem` }}>
        <Grid item xs><Typography variant="h5" style={{ marginBottom: `1.0rem`, marginTop: `1.0rem` }}>Blog</Typography></Grid>
        <Grid item xs><Tag /></Grid>
      </Grid>
      <Grid item>
        {renderBlogPostsList()}
      </Grid>
    </Grid>
  )
}

export default BlogPosts