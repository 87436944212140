import React from "react"
import { Link } from "gatsby"
import { makeStyles, Grid, Typography } from "@material-ui/core"
import moment from "moment"

const useStyles = makeStyles(theme => ({
  postContainer: {
    marginBottom: `1.75rem`
  },
  h5: {
    color: `black`
  },
  body2: {
    fontFamily: `Spectral`,
    color: `black`
  },
  caption: {
    fontSize: `85%`,
    color: `darkgrey`
  },
  link: {
    textDecorationLine: `none`
  }
}))

const PostPreview = props => {
  const classes = useStyles()
  const post = props.post
  //console.log(post)
  return (
    <Link to={`/blog/${post.slug}`} className={classes.link}>
      <Grid item xs={12} direction="column" className={classes.postContainer}>
        <Typography variant="h5" className={classes.h5}>{post.metadata.post_title}</Typography>
        <Typography variant="caption" className={classes.caption}>
          {moment(post.metadata.post_date).format(`MMMM Do YYYY`)} • {moment(post.metadata.post_date).fromNow()}
        </Typography>
        <Typography variant="body2" className={classes.body2}>{post.metadata.post_spoiler}</Typography>
      </Grid>
     </Link>
  )
}

export default PostPreview