import React from "react"
// import { Router } from "@reach/router"

import Layout from "../components/layout"
import BlogPosts from "../components/BlogPosts/blogPosts"
import SEO from "../components/seo"
// import { Post } from "../components/Post/post"

const Blog = () => {
  return (
    <div>
      <SEO title="Blog" lang="en"
          description="Articles about stuff I'm interested in"
          meta={[`Zain`, `Khalid`, `Zain Khalid`, `Software`, `Software Developer`, `Developer`,
          `Full Stack`, `Full`, `Full Stack Developer`, `Android`, `Android Developer`, `Blog`,
          `Post`, `Posts`, `Article`, `Articles`, `Blog Posts`]}
      />
      <Layout>
        <BlogPosts />
      </Layout>
    </div>
  )
}

export default Blog
