import React, { useState } from "react"
import { makeStyles, Chip } from "@material-ui/core"

const useStyles = makeStyles (theme => ({
  chip: {
      margin: theme.spacing(0.5),
      width: `fit-content`,
      height: theme.spacing(4.0),
      padding: theme.spacing(0.5),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
  },
  chipIcon: {
    marginTop: `24px`,
    padding: `2px`
  }
  

}));

const TagItem = props => {
  const tag = props.tag
  const [isSelected, setSelected] = useState(false)
  //console.log(tag)
  let icon = <img src={tag.metadata.tag_image.url} alt={`${tag.metadata.tag_name}-tag`} width="24" className={useStyles().chipIcon} />
  return (
    <Chip key={tag.metadata.tag_name} icon={icon} label={tag.metadata.tag_name} size="small"
          selected={isSelected} variant={isSelected ? 'default' : 'outlined'} onClick={() => setSelected(!isSelected) } className={useStyles().chip} />
  )
}

export default TagItem