import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Grid, Typography } from "@material-ui/core"

import TagItem from "./tagItem"

const Tag = () => {
  const data = useStaticQuery(graphql`
    query TagQuery {
      allCosmicjsTags {
        nodes {
          metadata {
            tag_name
            tag_image {
              url
            }
          }
        }
      }
    }
  `)
  const tags = data.allCosmicjsTags.nodes

  //console.log(tags)
  const renderTagList = () => {
    return tags.map((tag, i) => <TagItem key={i} tag={tag} />)
  }

  return (
    <Grid container justify="flex-start" alignItems="center" direction="column" spacing={1}>
      <Typography variant="overline" style={{ marginRight: `0.5em`, marginLeft: `1.0em`, fontWeight: `600`, color: `darkred` }}>NOTE: Filters here for show, Under Construction <span role="img" aria-label="starry eyes">🤩</span></Typography>
      <Grid container justify="flex-start" alignItems="center" direction="row" spacing={1}>
        {renderTagList()}
      </Grid>
    </Grid>
  )
}

export default Tag